import React, { useContext, useEffect } from 'react';

import Tooltip from '@atlaskit/tooltip';
import { MdClose } from 'react-icons/md';
import { NavigationContext } from '~/context/navigation';
import { FilterContext } from '~/context/filter';
import { Container } from './styles';
import icones from '~/config/icones';
import { useQueryClient } from 'react-query';
import api from '~/services/api';
import { useHistory } from 'react-router-dom';
import useAuth from '~/hooks/useAuth';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import { loadingContext } from '~/context/loading';
import { LojaContext } from '~/context/loja';

interface NavigationProps {
  url?: string;
}

const Navigation: React.FC<NavigationProps> = ({ url }) => {
  const { user } = useAuth();
  const history = useHistory();
  const { removeScreen, navmenu, addScreen, screens, loader, setScreenActive } =
    useContext(NavigationContext);
  const { addScreenInfo, removeScreenInfo } = useContext(
    InformacaoTelasAcessadasContext,
  );

  const { loja } = useContext(LojaContext);

  const { handleLoadingPageSearchRemove, handleLoadingTableSearchRemove } =
    useContext(loadingContext);
  const { RemoveAllFilter } = useContext(FilterContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      if (url) {
        const telas: any = navmenu.filter((tela: any) => {
          return tela.url === url;
        });

        const contemTela: any = screens.filter((telaActive) => {
          return telaActive.cod_tela === telas[0].id;
        });

        if (contemTela.length === 0) {
          if (telas.length > 0) {
            let hasPermission: any;
            if (user?.controleAcessoTela)
              hasPermission = user?.controleAcessoTela.find(
                (tela) => tela.cod_tela === telas[0].id,
              );
            if (hasPermission) {
              if (telas && telas[0]) {
                const { data } = await api.post('/tela-por-codigo', {
                  cod_tela: telas[0].id,
                });
                if (data.data && data.success) {
                  const tela = {
                    cod_tela: telas[0].id,
                    content: telas[0].content,
                    des_icon: data.data[0].des_icon,
                    label: data.data[0].label_menu,
                    lib_icon: data.data[0].lib_icon,
                    url: telas[0].url,
                    linkMaterial: data.data[0].link_material,
                    flg_abre_pesquisa: data.data[0].flg_abre_pesquisa,
                  };

                  if (telas[0].id === 280 && !user.flg_lib_financeiro) {
                    // não abrir a tela quando user.flg_lib_financeiro for false
                    return false;
                  }

                  queryClient.setQueryData(`cod_tela_active`, {
                    cod_tela: tela.cod_tela,
                  });
                  addScreen(tela);
                  addScreenInfo(tela);
                }
              }
            }
          }
        } else {
          queryClient.setQueryData(`cod_tela_active`, {
            cod_tela: contemTela[0].cod_tela,
          });
          setScreenActive(contemTela[0].cod_tela);
        }
        // history.push('/app');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, url]);

  const getArrayIndexed = function (
    arr: any[],
    fieldNameKey: string,
    fieldNameValue: string,
  ) {
    const r: any = {};
    for (let i = 0; i < arr.length; i++)
      r[`${arr[i][fieldNameKey]}`] = arr[i][fieldNameValue];
    return r;
  };

  const ulrTelas = getArrayIndexed(navmenu, 'id', 'url');

  /**
   * Código será utilizado para evitar reload adicional no futuro portanto
   * NÃO REMOVER
   * window.addEventListener('beforeunload', function (e) {
    if (screens.length > 0) {
      e.preventDefault();
      e.returnValue = '';
    }
  });
   */

  if (loader) {
    return null;
  }
  return (
    <Container>
      <ul className="nav" id="myTab" role="tablist">
        {screens.map(
          ({ active, label, lib_icon, des_icon, cod_tela, url: urlTela }) => {
            const iconkey = Object.keys(icones).find(
              (icon) => icon === lib_icon,
            );
            let Icon = '';
            if (iconkey) {
              const Lib = icones[iconkey];
              Icon = Lib[des_icon];
            }
            return (
              <li role="presentation" data-bs-toggle="tab" key={label}>
                <div
                  className={`nav-link ${active ? 'active' : ''} ${
                    Number(cod_tela) === 204 ? 'activeHome' : ''
                  }`}
                  id={`${cod_tela}-tab`}
                  data-bs-toggle="tab"
                  onClick={() => {
                    queryClient.setQueryData(`cod_tela_active`, {
                      cod_tela,
                    });
                    setScreenActive(cod_tela);
                    history.push(urlTela || ulrTelas[`${cod_tela}`]);
                  }}
                  aria-controls={String(cod_tela)}
                  aria-selected={active ? 'true' : 'false'}
                >
                  {Icon && <Icon />}
                  {cod_tela !== 204 && (
                    <>
                      <span className="mx-2">{label}</span>
                      <Tooltip content="Fechar">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            queryClient.removeQueries(`cache_tela_${cod_tela}`);
                            queryClient.removeQueries(
                              `tipo_regime_loja_${loja.cod_loja}`,
                            );
                            queryClient.removeQueries(
                              `infoCardAtivo_${cod_tela}`,
                            );
                            queryClient.removeQueries(
                              `cacheLastSearch_${cod_tela}`,
                            );
                            queryClient.removeQueries(
                              'logs_processamento_manual',
                            );
                            handleLoadingPageSearchRemove(cod_tela);
                            handleLoadingTableSearchRemove(cod_tela);
                            removeScreen(label);
                            removeScreenInfo(cod_tela);
                            RemoveAllFilter(Number(cod_tela));
                          }}
                        >
                          <MdClose />
                        </button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </li>
            );
          },
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        {screens.map(({ content: Content, active, cod_tela }) => {
          if (active) {
            if (cod_tela === 204) {
              return (
                <div
                  key={cod_tela}
                  className="tab-pane fade show active"
                  id={String(cod_tela)}
                  role="tabpanel"
                  aria-labelledby={`${cod_tela}-tab`}
                >
                  <Content activated navmenu={navmenu} />
                </div>
              );
            }
            return (
              <div
                key={cod_tela}
                className="tab-pane fade show active"
                id={String(cod_tela)}
                role="tabpanel"
                aria-labelledby={`${cod_tela}-tab`}
              >
                <Content activated navmenu={navmenu} />
              </div>
            );
          }
          return (
            <div
              key={cod_tela}
              className={`tab-pane fade `}
              id={String(cod_tela)}
              role="tabpanel"
              aria-labelledby={`${cod_tela}-tab`}
            >
              <Content />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Navigation;
