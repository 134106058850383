import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

export const Container = styled.header`
  font-family: 'Roboto', sans-serif;
  min-height: 50px;
  background-color: ${({ theme: { colors } }) => colors.primary};

  nav {
    min-height: 50px;

    .nav-container {
      display: flex;
      flex-flow: row wrap;
      margin: auto;
      min-height: 50px;

      .nav-logo {
        padding-left: 15px;
        display: flex;
        gap: 10px;

        transition: .2s;

        a {
          display: inline-flex;
          img {
            margin: auto;
            width: 130px;
          }
        }
        .nav-openMenu {
          display: flex;
          position: relative;
          height: 100%;


          button {
            background: ${({ theme: { colors } }) => colors.primary};
            color: #fff;
            padding: 0px 12px;
            margin-right: 8px;

            border: 1px solid ${({ theme: { colors } }) => colors.primary};

            transition: 0.2s;
            cursor: pointer;

            &:hover {
              background: #400275;
            }

            svg {
              font-size: 1.5rem;
            }

            span {
              margin-left: 5px;
            }
            &:focus:not(.focus-visible) {
              outline: none;
              background: #400275;
            }
            &:focus-visible {
              outline: 1px solid ${({ theme: { colors } }) =>
                colors.secondary} !important;
            }
          }
        }

        @media (max-width: 940px) {
          width: 100%;
          min-height: 50px;
          justify-content: space-between;
        }
      }

      .nav-form {
        z-index: 500;
        flex-grow: 1;

        .nav-search {
          display: flex;
          align-items: center;
          width: 60%;
          height: 100%;

          .nav-form-search {
            display: flex;
            align-items: center;
            width: 100%;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.3);

            &:focus-within {
              outline: 1px solid #a1a1a1;
            }

            svg {
              font-size: 1.2rem;
              position: absolute;
              margin-left: 10px;
              color: #fff;

              cursor: text;
              outline: none;
            }

            input {
              width: 95%;
              height: 35px;
              background: transparent;
              padding-left: 40px;
              color: #f0f0f0;

              border: none;
              &::placeholder {
                color: #a1a1a1;
              }
            }

            .nav-close {
              display: flex;

              button {
                background: transparent;

                border: none;
                outline: none;
                cursor: pointer;

                svg {
                  margin: auto;
                  position: relative;
                  font-size: 2rem;
                  color: #fff;

                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }

            @media (max-width: 940px) {
              svg {
                visibility: hidden !important;
                display: none !important;
              }
              input {
                visibility: hidden !important;
                display: none !important;
              }
              .nav-close {
                visibility: hidden !important;
                display: none !important;
              }
            }
          }

          .nav-dropPanel {
            top: 50px;
            left: 0px;
            right: 0px;
            bottom: -0px;
            display: flex;
            z-index: 10;
            position: fixed !important;

            transition: .2s;

            .backOpacity {
              position: fixed;
              top: 50px;
              right: 0px;
              bottom: 0px;
              left: 0px;
              background-color: rgba(0, 0, 0, 0.2);
            }

            .nav-panelMenu {
              display: flex !important;
              flex-grow: 1 !important;
              flex-direction: column !important;
              position: relative !important;

              .nav-menuTelas {
                display: flex !important;
                overflow: hidden !important;
                position: relative !important;
                flex-grow: 1 !important;
                box-shadow: rgb(0 0 0 / 40%) 2px 2px 4px,
                #693299 0px 1px 0px inset !important;
                box-sizing: border-box !important;
                padding-top: 10px !important;
                padding: 21px 26px 0px !important;
                flex-direction: column !important;
                pointer-events: auto !important;
                background-color: ${({ theme: { colors } }) =>
                  colors.submenu}; !important;

                .nav-splitMenuTelas {
                  height: 100% !important;
                  display: flex !important;
                  position: relative !important;
                  flex-grow: 1 !important;
                  flex-direction: row !important;

                  .input-mobile {
                    visibility: hidden !important;
                    display: none !important;
                    padding: 10px 30px 10px 30px !important;

                    .nav-form-search {
                      padding: 10px;
                      width: 100% !important;
                      align-items: center;
                      background: #400275;
                      margin-top: -1px;

                      &:focus-within {
                        outline: 1px solid #a1a1a1;
                      }

                      svg {
                        font-size: 1.2rem;
                        position: absolute;
                        margin-left: 10px;
                        color: #fff;
                        visibility: visible !important;
                        display: flex !important;

                        cursor: text;
                      }

                      input {
                        width: 95%;
                        height: 35px;
                        background: #400275;
                        padding-left: 40px;
                        color: #f0f0f0;

                        border: 1px solid #400275;
                        &::placeholder {
                          color: #a1a1a1;
                        }
                        visibility: visible !important;
                        display: flex !important;
                      }

                      .nav-close {
                        display: flex;
                        visibility: visible !important;
                        display: flex !important;

                        button {
                          background: #400275;
                          color: #fff;

                          border: 1px solid #400275;
                          cursor: pointer;

                          svg {
                            margin: auto;
                            position: relative;
                            font-size: 1.4rem;
                            color: #fff;
                          }
                        }
                      }
                    }
                  }

                  .left-menu {
                    display: flex !important;
                    overflow-y: auto !important;
                    overflow-x: hidden !important;
                    margin-top: 15px !important;
                    align-items: flex-start !important;
                    flex-shrink: 0 !important;
                    border-bottom: 1px solid #414750 !important;
                    scroll-behavior: smooth !important;
                    justify-content: space-between !important;
                    padding: 0px !important;

                    div {
                      .separator-modulo {
                        width: 11.25rem;

                        @media only screen and (min-width: 1500px) {
                          width: 14.375rem
                        }
                      }

                      li {
                      flex-shrink: 0 !important;

                        .top-button {
                          padding: 2px 5px 2px 5px;
                          color: #fff !important;
                          display: inline-flex !important;
                          box-sizing: content-box !important;
                          align-items: center !important;
                          white-space: break-spaces !important;
                          background: transparent !important;
                          border: none;

                          font-size: 14px !important;
                          letter-spacing: 0.25px !important;
                          outline: none !important;
                          cursor: pointer !important;

                          &:focus:not(.focus-visible) {
                            outline: none;
                          }
                          &:focus-visible {
                            outline: 1px solid ${({ theme: { colors } }) =>
                              colors.secondary} !important;
                          }
                        }

                        .li-button {
                          padding: 2px 5px 2px 5px;
                          display: inline-flex !important;
                          box-sizing: content-box !important;
                          align-items: center !important;
                          white-space: break-spaces !important;
                          background: transparent !important;
                          border: none;

                          letter-spacing: 0.25px !important;
                          color: #fff !important;
                          font-size: 14px !important;
                          outline: none !important;
                          cursor: pointer !important;

                          &:focus:not(.focus-visible) {
                            outline: none;
                          }
                          &:focus-visible {
                            outline: 1px solid ${({ theme: { colors } }) =>
                              colors.secondary} !important;
                          }
                        }

                        .config-menus {
                          color: #c6c6c6 !important;
                        }

                          .top-button {
                            margin: 2px !important;
                            line-height: 20px !important;
                          }
                          .li-button {
                            margin: 2px !important;
                            line-height: 20px !important;
                          }
                      }
                      .btn-activated {
                        background: #671ea7 !important;
                        border-radius: 3px;
                        width: 11.25rem;

                        @media only screen and (min-width: 1500px) {
                          width: 14.375rem
                        }
                      }
                    }
                    .responsive-menus {
                      visibility: hidden !important;
                      display: none !important;
                    }
                    @media (max-width: 1060px) {
                      .responsive-menus {
                        visibility: visible !important;
                        display: block !important;
                      }
                    }
                    @media (min-width: 940px) {
                      li {
                        margin-bottom: 8px !important;
                      }
                    }


                    /* width */
                      ::-webkit-scrollbar {
                        width: 8px;
                      }

                      /* Track */
                      ::-webkit-scrollbar-track {
                        background: #400275;
                      }

                      /* Handle */
                      ::-webkit-scrollbar-thumb {
                        background: #8955b5;

                        transition: 0.2s;
                      }

                      /* Handle on hover */
                      ::-webkit-scrollbar-thumb:hover {
                        background: #c790f5;
                      }
                  }

                  .right-menu {
                    display: flex !important;
                    flex-grow: 1 !important;
                    flex-direction: column !important;

                    .right-menuHeader {
                      flex-shrink: 0 !important;
                      margin-bottom: 25px !important;

                      span {
                        color: #AAB7B8 !important;
                        line-height: 20px !important;
                      }
                      @media (min-width: 940px) {
                        span {
                          font-size: 14px !important;
                        }
                      }
                    }

                    .righ-menuTelas {
                      outline: none !important;
                      overflow: hidden auto !important;
                      .ul-telasModulos {
                        padding-left: 0;
                        display: block !important;
                        min-height: 300px !important;

                        .painelMenuLoja {
                          height: 40px;
                          display: flex !important;
                          margin: 4px 0px 4px 0px !important;
                          line-height: 20px !important;
                          padding: 0;

                          .li-buttonLoja {
                            height: 40px;
                            flex-grow: 1 !important;
                            text-align: left !important;
                            background: transparent !important;
                            border: none;
                            padding: 0px 10px;

                            letter-spacing: 0.25px !important;
                            color: #fff !important;
                            font-size: 13px !important;
                            outline: none !important;
                            cursor: pointer !important;

                            transition: .1s;

                            &:focus {
                              outline: 1px solid ${({ theme: { colors } }) =>
                                colors.secondary}!important;
                            }

                            &:hover {
                              background: #671ea7 !important;
                            }
                          }

                          .li-buttonConfig {
                            height: 40px;
                            flex-grow: 1 !important;
                            background: transparent !important;
                            border: none;

                            letter-spacing: 0.25px !important;
                            color: #fff !important;
                            font-size: 13px !important;
                            outline: none !important;
                            cursor: pointer !important;

                            transition: .1s;

                            &:focus {
                              outline: 1px solid ${({ theme: { colors } }) =>
                                colors.secondary}!important;
                            }

                            &:hover {
                              background: #671ea7 !important;
                            }
                          }

                          .selected {
                            background: #671ea7 !important;
                          }
                        }

                        @media (min-width: 940px) {
                          li {
                            margin-top: 0px !important;
                          }
                        }
                      }
                      @media (max-width: 940px) {
                        .ul-telasModulos {
                          padding: 0 !important;
                        }
                      }
                      /* width */
                      ::-webkit-scrollbar {
                        width: 15px;
                      }

                      /* Track */
                      ::-webkit-scrollbar-track {
                        background: #400275;
                      }

                      /* Handle */
                      ::-webkit-scrollbar-thumb {
                        background: #8955b5;

                        transition: 0.2s;
                      }

                      /* Handle on hover */
                      ::-webkit-scrollbar-thumb:hover {
                        background: #c790f5;
                      }
                    }

                    @media (max-width: 940px) {
                      .right-menuHeader {
                        padding: 0px 30px 0px 30px !important;
                      }
                    }

                    @media (min-width: 940px) {
                      .righ-menuTelas {
                        padding-right: 10px !important;
                      }
                    }
                  }

                  @media (min-width: 941px) {
                    .left-menu {
                      width: 200px !important;
                      margin-top: 45px !important;
                      margin-right: 20px !important;
                      border-bottom: 0 !important;
                      flex-direction: column !important;
                    }
                  }
                  @media (max-width: 940px) {
                    .left-menu {
                      display: flex !important;
                      overflow: auto !important;
                      margin-top: 15px !important;
                      width: 100%;
                      flex-direction: column !important;
                      align-items: stretch !important;
                      justify-content: space-between !important;
                      margin-bottom: 1rem;
                      padding-left: 2rem;
                      border-bottom: none !important;
                      /* borda */
                      border-bottom: 1px solid #654694 !important;
                      div {
                        margin: 0px;
                        padding: 0px;
                        outline: none;
                        box-sizing: border-box;
                        li {
                          flex-shrink: 0 !important;
                          margin-bottom: 8px !important;
                          box-sizing: border-box;
                        }
                      }
                    }
                    .input-mobile {
                      visibility: visible !important;
                      display: flex !important;
                    }
                  }

                }

                @media (max-width: 940px) {
                  .nav-splitMenuTelas {
                    flex-direction: column !important;
                    overflow: auto !important;
                    /* width */
                    ::-webkit-scrollbar {
                      width: 20px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                      background: #400275;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                      background: #8955b5;

                      transition: 0.2s;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                      background: #c790f5;
                     }
                  }

                }
              }
              @media (min-width: 940px) {
                .nav-menuTelas {
                  padding: 21px 36px 0px !important;
                  max-width: 1440px !important;
                  border-radius: 0px 0px 4px 4px !important;
                  margin-bottom: 10px !important;
                }
              }
            }
            @media (min-width: 940px) {
              .nav-panelMenu {
                margin: 0 auto;
                max-width: 1440px !important;
                margin-bottom: 10px !important;
                border-radius: 0px !important;
              }
            }
            @media (min-width: 1440px) {
              bottom: 29px;
            }
          }
        }
      }

      .nav-menus {
        height: 50px;
        width: 34.375rem;
        display: flex;
        color: #fff;

        .nav-menu {
          display: flex;
          height: 100%;
          position: static;
          margin: auto;

          & + .nav-menu {
            margin-left: 20px;
          }

          a.btn-closeWindows {
            text-decoration: none;
            height: 100%;
            margin: auto;
            background: ${({ theme: { colors } }) => colors.primary};
            border: none;
            padding-left: 15px;
            padding-right: 15px;
            color: #fff;

            transition: 0.2s;
            cursor: pointer;

            display: flex;
            align-items: center;

            svg {
              font-size: 1.4rem;
            }
            span {
              font-size: 0.84rem;
              margin-left: 5px;
            }

          }

          button {
            height: 100%;
            margin: auto;
            background: ${({ theme: { colors } }) => colors.primary};
            border: none;
            padding-left: 15px;
            padding-right: 15px;
            color: #fff;

            transition: 0.2s;
            cursor: pointer;

            &:hover {
              background: rgba(0, 0, 0, 0.3);
            }

            svg {
              font-size: 1.4rem;
            }
            span {
              font-size: 0.84rem;
              margin-left: 5px;
            }
            &:focus:not(.focus-visible) {
              outline: none;
              background: #400275;
            }
            &:focus-visible {
              outline: 1px solid ${({ theme: { colors } }) =>
                colors.secondary} !important;
            }
          }

          .nav-user-logged {
            svg {
              margin-right: 0.3125rem;
            }
          }

          .nav-dropPanelLojas {
            top: 50px;
            left: auto;
            right: 0px;
            display: flex;
            z-index: 99999999999999;
            position: absolute !important;
            pointer-events: inherit !important;

            .backOpacityLoja {
              position: fixed;
              top: 50px;
              right: 0px;
              bottom: 0px;
              left: 0px;
              background-color: rgba(0, 0, 0, 0.2);
            }

            .nav-panelMenuLojas {
              display: flex !important;
              flex-grow: 1 !important;
              flex-direction: column !important;
              position: relative !important;

              .nav-menuLojas {
                outline: none !important;
                margin: 0px 45px 0px auto;
                display: flex !important;
                overflow: hidden !important;
                position: relative !important;
                box-shadow: rgb(0 0 0 / 40%) 2px 2px 4px,
                #693299 0px 1px 0px inset !important;
                box-sizing: border-box !important;
                padding: 20px !important;
                flex-direction: column !important;
                pointer-events: auto !important;
                background-color: ${({ theme: { colors } }) =>
                  colors.submenu} !important;
                border-radius: 0px 0px 3px 3px;

                .menuHeader {
                  margin-bottom: 10px !important;

                  display: flex;
                  justify-content: center;

                  span {
                    color: rgba(255, 255, 255, 0.8);
                    line-height: 20px !important;
                  }
                }

                .menuLojas {
                    display: flex !important;
                    flex-direction: column !important;
                    padding: 0;

                    li {
                      height: 40px;
                      display: flex !important;
                      margin: 4px 0px 4px 0px !important;
                      line-height: 20px !important;
                      padding: 0;

                      .li-buttonLoja {
                        height: 40px;
                        flex-grow: 1 !important;
                        text-align: left !important;
                        background: transparent !important;
                        border: none;
                        border-radius: 4px;

                        letter-spacing: 0.25px !important;
                        color: #fff !important;
                        font-size: 13px !important;
                        outline: none !important;
                        cursor: pointer !important;

                        display: block;
                        align-items: center;
                        gap: 0.3125rem;
                        width: 9.5rem;


                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                        white-space: nowrap !important;
                        max-width: 400px !important;
                        transition: .1s;

                        &:focus {
                          outline: 1px solid ${({ theme: { colors } }) =>
                            colors.secondary}!important;
                        }

                        &:hover {
                          background: #57069e !important;
                        }
                      }
                      .li-buttonNotificacao {
                        height: 40px;
                        text-align: left !important;
                        background: transparent !important;
                        border: none;
                        border-radius: 4px;

                        letter-spacing: 0.25px !important;
                        color: #fff !important;
                        font-size: 13px !important;
                        outline: none !important;
                        cursor: pointer !important;
                        gap: 0.3125rem;
                        width: 30rem;

                        transition: .1s;

                        &:focus {
                          outline: 1px solid ${({ theme: { colors } }) =>
                            colors.secondary}!important;
                        }

                        &:hover {
                          background: #57069e !important;
                        }
                      }
                      .li-buttonConfig {
                        height: 30px;
                        flex-grow: 1 !important;
                        background: transparent !important;
                        border: none;

                        letter-spacing: 0.25px !important;
                        color: #fff !important;
                        font-size: 13px !important;
                        outline: none !important;
                        cursor: pointer !important;

                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.3125rem;

                        transition: .1s;

                        &:focus {
                          outline: 1px solid ${({ theme: { colors } }) =>
                            colors.secondary}!important;
                        }

                        &:hover {
                          background: #671ea7 !important;
                        }
                      }

                      .selected {
                        background: #671ea7 !important;
                      }
                    }
                  }

              }
            }
          }
        }
      }

      .separator {
        margin: auto;
        margin-left: 2px;
        margin-right: 2px;
        height: 60%;
        width: 1px;
        background: #693299;
      }
    }

    .nav-menus-row {
      visibility: hidden;
      display: none;
      height: 30px;
      background: rgba(72, 5, 130, 0.2);

      .nav-menus {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 30px;
        color: #fff;

        .nav-menu {
          width: 100%;
          display: flex;
          height: 100%;
          position: fixed;
          margin: auto;
          background: rgba(72, 5, 130, 0.2);

          & + .nav-menu {
            margin-left: 20px;
          }

          button {
            flex: 1;
            height: 100%;
            margin: auto;
            background: rgba(72, 5, 130, 0.2);
            border: none;
            padding-left: 15px;
            padding-right: 15px;
            color: #fff;

            transition: 0.05s;

            cursor: pointer;

            svg {
              font-size: 1.4rem;
            }
            span {
              font-size: 0.84rem;
              margin-left: 5px;
            }

            &:hover {
              background: #480582;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1720px) {
    nav {
      .nav-container {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
  @media (max-width: 1060px) {
    nav {
      .nav-container {
        .nav-form {
          .nav-search {
            width: 100%;
          }
        }
        .nav-menus {
          visibility: hidden;
          display: none;
        }
      }
      .nav-menus-row {
        visibility: visible;
        display: flex;
      }
    }
  }
`;

export const Navb = styled(Navbar)`
  font-family: 'Roboto', sans-serif;
  height: 50px;
  background-color: ${({ theme: { colors } }) => colors.primary};
  transition: 0.3s;

  .container {
    max-width: 1720px;
    height: 50px;

    .navbar-brand {
      width: 140px;
      display: flex;
      img {
        margin-top: -5px;
        width: 100%;
      }
    }

    .navbar-collapse {
      display: flex;
      height: 50px;
      background: green;

      .input-search {
      }

      form {
        height: 50px;

        .input-group {
          height: 50px;

          input {
            height: 38px;
            border-radius: 10px 0px 0px 10px;
            margin: auto;
          }
          .search-button {
            height: 40px;
            border-radius: 0px 10px 10px 0px;
            background-color: ${({ theme: { colors } }) => colors.primary};
            border-color: #57079e;
            width: 53px;
          }
        }
      }

      .menu-button {
        width: 100px;
        height: 40px;
        background-color: transparent;
        font-size: 18px;
        color: #fff;
        border: none;
      }
      .loja-button {
        width: 100px;
        height: 40px;
        background-color: transparent;
        font-size: 18px;
        color: #fff;
        border: none;
        margin-top: 2px;
        svg {
          margin-left: 3px;
        }
      }
    }

    .navbar-nav .nav-link {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      color: #ffffff;
    }

    .nav-item .dropdown-toggle {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      color: #ffffff;
    }

    // large screen
    @media (min-width: 991px) {
      padding: 0px;
      transition: 0.3s;
    }
    // small screen
    @media (max-width: 991px) {
      padding-left: 0px;
      padding-right: 0px;
      transition: 0.3s;
      .navbar-collapse {
        background-color: ${({ theme: { colors } }) => colors.nav};
      }
    }
  }
`;
